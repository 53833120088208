const apiprefix = {
  insertPrefixList: "/v1/api/AdsPrefix/InsertPrefix",
  updatePrefixList: "/v1/api/AdsPrefix/UpdatePrefix/",
  deletePrefix: "/v1/api/AdsPrefix/DeletePrefix/",
  getPrefixList: "/v1/api/AdsPrefix/PrefixList",
  getPrefixListByID: "/v1/api/AdsPrefix/PrefixByID/",
  // duplicate: "/v1/api/AdsPrefix/PrefixListAgency",
  checkusedstatus: "/v1/api/AdsPrefix/CheckUsageStatusPrefix/",
};

export default apiprefix;
