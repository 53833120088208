export const typeSchema = [
  {
    group: [
      {
        class: "row m-0 gx-3 p-0",
        group: [
          {
            class: "row m-0 gx-3 p-0",
            group: [
              {
                class: "row m-0 gx-3 p-0",
                group: [
                  {
                    class: "col-12 d-flex justify-content-between mb-4",
                    component: [
                      {
                        name: "10",
                        label: "201", // Prefix information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle ",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row m-0 gx-3 p-0",

                group: [
                  {
                    class: "col-lg-8 col-md-12 col-sm-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      // Prefix name (TH)
                      {
                        label: "108",
                        type: "InputText",
                        datavalue: "strPrefixNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpPrefixNameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-4 col-md-12 col-sm-12 mb-3 ps-lg-2 p-sm-0 p-0",
                    component: [
                      // Prefix initial (TH)
                      {
                        label: "110",
                        type: "InputText",
                        datavalue: "strPrefixInitailTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpPrefixInitialTH",
                        validate: "InitialTH",
                        // request: true,
                        maxlength: 100,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row",

                group: [
                  {
                    class: "col-lg-8 col-md-12 col-sm-12 mb-3 pe-lg-2 p-sm-0 p-0 mb-3",
                    component: [
                      // Prefix name
                      {
                        label: "107",
                        type: "InputText",
                        datavalue: "strPrefixNameEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpPrefixNameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  }, {
                    class: "col-lg-4 col-md-12 col-sm-12 mb-3 ps-lg-2 p-sm-0 p-0",
                    component: [
                      // Prefix initial
                      {
                        label: "109",
                        type: "InputText",
                        datavalue: "strPrefixInitailEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpPrefixInitialEN",
                        validate: "InitialEN",
                        // request: true,
                        maxlength: 100,
                      },
                    ],
                  },

                ],
              },

              {
                class: "row",

                group: [
                  {
                    class:
                      "p-0 modal-footer border-0 justify-content-start pt-0 mt-6 mb-3",
                    component: [
                      {
                        type: "Button",
                        name: "36", // OK
                        value: false,
                        state: "submit",
                        class: "btn-fix me-3",
                      },
                      {
                        type: "Button",
                        name: "35", // Cancel
                        value: false,
                        state: "cancel",
                        class: "btn-fix btn-cancel",
                      },
                    ],
                  },
                ],
              },

            ],
          },
          {
            class: "w-100 d-flex justify-content-center",
            group: [
              {
                component: [
                  {
                    class: "mt-3",
                    type: "DeleteinForm",
                    icon: "DeleteFill",
                    classBtnIcon: "fill-secondary",
                    datavalue: "blnDeleteinForm",
                    state: "delete",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export const request = [
  "strPrefixNameEN",
  "strPrefixNameTH",
  // "strPrefixInitailEN",
  // "strPrefixInitailTH",
];
