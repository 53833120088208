<template>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('113')"
        :Helper="$t('345')"
        @confirm="fetchDeletePrefix($event)"
        :blnLoading="blnloadConfirmDelet"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="blnloadConfirmDelet == false ? ConfirmDelete() : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('1287', { LONGINFAILNUM: intPinCount, LockContact: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage()"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('223')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <!-- </div> -->
  <Header
    icon="prefixOutline"
    :headerName="$t('10')"
    :btnName="$t('111')"
    :breadcrumbname="
      action == 'Insert' ? $t('111') : '' || action == 'Update' ? $t('399') : ''
    "
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="blnOpenform == true ? '' : 'd-none'"
    :helperHeader="
      blnOpenform == true
        ? $t('200', { fieldname: $t('10').toLowerCase() })
        : ''
    "
    :hintHelperHeader="blnOpenform == true ? '' : 'd-none'"
    :rightHeader="blnOpenform == true ? 'd-none' : ''"
    :underline="blnOpenform == true ? 'openUnderline' : 'closeUnderline'"
    @showFilter="openFilter($event)"
    id="header"
    ListPrefixClass="d-none"
    :Searchname="$t('112')"
    @onSearch="SearcEducation($event)"
    @onClean="clean()"
    :blnOpenform="blnOpenform"
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: true,
    }"
    :tooltipMsg="'101'"
    @ConfigDisplay="reloadConfigDisplay()"
    :tooltipPlacement="
      device == 'mobile' ? 'tooltip-top-mobile' : 'tooltip-top'
    "
    ref="header"
  />
  <Table
    :progressScore="progress"
    :nametable="'10'"
    v-show="ListPrefix.dataFields != null"
    v-if="!blnOpenform"
    :ListDataTable="ListPrefix"
    firstSort="-"
    secondSort="strPrefixNameEN"
    @edit="fetchGetPrefixbyId($event)"
    @delete="fetchDeletePrefix($event)"
    @ConfigDisplay="reloadConfigDisplay()"
    @onSaveConfigDisplay="onSaveConfigDisplay($event)"
    ref="table"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listPrefixFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnHaveDecision="false"
        :blnOpenFilter="blnOpenFilter"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-lg-fix mb-4'"
    >
      <div
        class="p-5 d-flex flex-column gap-5 justify-content-center"
        style="width: 846px; height: 274px"
        v-if="blnloading == true"
      >
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 70%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 30%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
        <div class="d-flex gap-3">
          <div class="skeleton rounded" style="width: 70%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
          <div class="skeleton rounded" style="width: 30%; height: 40px">
            <div class="skeleton-item rounded mb-3"></div>
          </div>
        </div>
      </div>
      <div v-show="blnloading == false">
        <Form
          v-if="blnOpenform == true"
          :typeSchema="typeSchema"
          :data="formdata"
          @onInput="openform($event)"
          @Insert="fetchInsertPrefix($event)"
          @Update="fetchUpdatePrefix($event)"
          @delete="ConfirmDelete()"
          :action="action"
          :request="request"
          :inactive="formInactive"
          :msgalertDataDuplicate="msgalertDataDuplicate"
          ref="form"
        />
      </div>
    </div>
    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-3 mb-3 w-100' : ''"
      v-if="blnOpenform == true && listRelate.used == true"
      :listRelate="listRelate"
      :label="$t('418', { fieldname: $t('10').toLowerCase() })"
      style="width: 350px"
      class="layout"
    />
  </div>
  <!-- <Prefix :datatable="prefix" v-if="!blnOpenform" />
  <div class="w-50 bg-white admin-form-Prefix rounded-2 shadow-sm ms-9 mb-5">
    <Form
      v-if="blnOpenform"
      :typeSchema="typeSchema"
      :data="formdata"
      @onInput="openform($event)"
    />
  </div> -->
</template>

<script>
import Relate from "@/components/cards/relate.vue";
import Header from "@/components/headerMenu/haeder.vue";
// import Prefix from '../components/table/prefix.vue'
import { typeSchema, request } from "@/components/constants/schemaAddPrefix";
import Form from "@/components/form/form.vue";
import Table from "@/components/table/table.vue";
import Filter from "@/components/filter/filter.vue";
import urlapi from "@/typescript/urlapi/url";
import apiprefix from "@/typescript/urlapi/adminsystem/APIPrefix";
import axios from "axios";
import Enumerable from "linq";
import AltStatus from "@/components/alert/alertStatus.vue";
import Alert from "@/components/alert/alertPopup.vue";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
import VueCookies from "vue-cookies";
//import SortArray from "../typescript/sort/sorting";
export default {
  components: {
    Header,
    Form,
    Table,
    Filter,
    AltStatus,
    Relate,
    Alert,
  },
  data() {
    return {
      blnloadError: false,
      blnloadConfirmDelet: false,
      blnloading: false,
      strSearch: "",
      intWrongLogin: 0,
      blnPopupConfirmDelete: false,
      timerCountHover: 0,
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      hover: false, //hover alt
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      typeSchema: typeSchema, //
      timerEnabled: false,
      timerCount: 0,
      request: request,
      intPinCount: 1,
      progress: 0,
      formdata: {},
      blnOpenform: false,
      activeFiltersSelected: [],
      ListPrefix: {},
      ListDummy: {},
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      listPrefixFilter: [],
      PrefixId: { type: Number },
      formdataDummy: {},
      listRelate: [],
      formInactive: [],
      blnError: false,
      updateAdsInventories: null,
      errorMsg: "",
    };
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    this.updateAdsInventories = setInterval(() => {
      // console.log(AdsInventories.blnCloseform);
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 300);
  },
  beforeCreate() {},
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount: {
    //   handler(value) {
    //     if (value < 10 && this.timerEnabled && this.progress < 75) {
    //       setTimeout(() => {
    //         this.timerCount++;

    //         this.progress += 5 * this.timerCount;
    //       }, 200);
    //     }
    //   },

    //   immediate: true, // This ensures the watcher is triggered upon creation
    // },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    // confirm popUp delete
    ConfirmDelete() {
      this.blnError = false;
      this.$refs.AlertPopup.blnShowMSG = false;
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    // skeleton loading ข้อมูลคำนำหน้าชื่อ
    async processLoadingData() {
      this.progress = 0;
      await this.fetchListPrefix().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            // console.log("100");
            if (this.blnloadError == false) {
              this.progress = 100;
            }
          }, 1000);
          //this.progress = 100;
        });
      });
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      try {
        setTimeout(() => {
          this.ListPrefix.data = Enumerable.from(this.ListPrefix.data)
            .orderByDescending((r) =>
              r.strPrefixNameEN.toString().toLowerCase()
            )
            .toArray();
          this.ListPrefix.data = this.ListPrefix.data.reverse();
        }, 1000);

        if (
          localStorage.getItem("SelectedListPrefix") == null ||
          localStorage.getItem("SelectedListPrefix") == "undefined"
        ) {
          localStorage.setItem(
            "SelectedListPrefix",
            JSON.stringify(this.ListPrefix.defaultField)
          );
        }

        setTimeout(() => {
          if (this.$refs.table != null) {
            this.$refs.table.onClean();
            this.$refs.table.calculatePage(
              this.ListPrefix.total,
              this.ListPrefix.data
            );
            // ค่า config ไม่เป็น undefined
            if (localStorage.getItem("SelectedListPrefix") != "undefined") {
              this.$refs.table.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListPrefix"))
              );
            } else {
              // ค่า config เป็น undefined ให้ใช้ config จาก defaultfield
              // และ set ค่า config นั้นไว้
              localStorage.setItem(
                "SelectedListPrefix",
                JSON.stringify(this.ListPrefix.defaultField)
              );
              this.$refs.table.setSelectedList(this.ListPrefix.defaultField);
            }
          }
        }, 1000);
        if (this.$refs.filter != null) {
          this.$refs.filter.Clear();
        }
      } catch (error) {
        this.blnAltLoadingData = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltLoadingData = false;
        }, 5000);
        throw new Error(error);
      }
    },
    // เพิ่มข้อมูลตำนำหน้าชื่อ
    async fetchInsertPrefix(data) {
      this.msgalertDataDuplicate = [];
      // var stringToJsonObject = JSON.stringify(data);
      // console.log(stringToJsonObject)
      try {
        var res = await axios.post(
          urlapi +
            apiprefix.insertPrefixList +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            "&Confirm=true",
          data
        );
        if (res.data.inserted == true) {
          this.blnOpenform = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "26";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          this.progress = 0;
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.processLoadingData();
          }, 1000);
        }
        if (res.data.inserted == false) {
          if (res.data.prefixnameEN == true) {
            let Message = { name: "strPrefixNameEN", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }
          if (res.data.prefixnameTH == true) {
            let Message = { name: "strPrefixNameTH", msg: "221" };
            this.msgalertDataDuplicate.push(Message);
          }

          this.$refs.form.detectalertDataDuplicate(this.msgalertDataDuplicate);
        }
        if (res.data.code === 500) {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;
        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    // อัพเดทคำนำหน้าชื่อ
    async fetchUpdatePrefix(data) {
      if (this.$refs.header != null) {
        this.$refs.header.onClear();
      }
      this.msgalertDataDuplicate = [];
      let strPrefixNameEN = data.strPrefixNameEN;
      let strPrefixNameTH = data.strPrefixNameTH;
      let strPrefixInitailEN = data.strPrefixInitailEN;
      let strPrefixInitailTH = data.strPrefixInitailTH;
      const formdata = {
        strPrefixNameEN,
        strPrefixNameTH,
        strPrefixInitailEN,
        strPrefixInitailTH,
      };

      let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);

      if (isEqual == true) {
        await axios
          .get(urlapi + apiprefix.getPrefixListByID + this.PrefixId)
          .then((res) => {
            if (
              res.data.message ==
              "Not found ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              this.progress = 0;
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
      if (isEqual == false) {
        await axios
          .put(
            urlapi +
              apiprefix.updatePrefixList +
              this.PrefixId +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              "&Confirm=true",
            formdata
          )
          .then((res) => {
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              this.progress = 0;
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.processLoadingData();
              }, 1000);
            }
            if (res.data.edited == false) {
              if (res.data.prefixnameEN == true) {
                let Message = { name: "strPrefixNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              if (res.data.prefixnameTH == true) {
                let Message = { name: "strPrefixNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                // console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 5000);
              }
              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch(() => {
            this.blnAltStatusunsucess = true;
            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },
    // ดึงข้อมูลคำนำหน้าชื่อด้วยไอดี
    async fetchGetPrefixbyId(PrefixId) {
      this.formInactive = [];
      try {
        var resRelate = await axios.get(
          urlapi + apiprefix.checkusedstatus + PrefixId
        );
        this.listRelate = { ...resRelate.data };
        if (this.listRelate.used == true) {
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          this.formInactive.push(activeDelete);
          // console.log(this.formInactive);
        } else {
          this.formInactive = {};
        }
        // console.log(this.listRelate);
      } catch (error) {
        console.log(error);
      }
      this.PrefixId = PrefixId;
      await axios
        .get(urlapi + apiprefix.getPrefixListByID + PrefixId)
        .then((res) => {
          this.action = "Update";
          this.formdata = res.data.data;
          this.formdataDummy = { ...res.data.data };
          this.blnOpenform = true;
          if (
            res.data.message ==
            "Not found ,Please check mapping Id in interface"
          ) {
            this.blnOpenform = false;
            this.blnnotedit = true;
            setTimeout(() => {
              this.blnnotedit = false;
              this.processLoadingData();
            }, 5000);
          }
        });
    },
    // reloadpage ไปหน้า logout
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    //ลบข้อมูลด้วย ID
    async fetchDeletePrefix(state) {
      try {
        this.blnloadConfirmDelet = true;
        if (state === false) {
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
          this.blnloadConfirmDelet = false;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              state,
              axios,
              urlapi
            );
            var login = {
              username: strUsername[0],
              password: state,
            };

            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.delete(
                urlapi + apiprefix.deletePrefix + this.PrefixId,
                {
                  data: login,
                },

                { headers: { "Content-Type": "application/json" } }
              );

              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupConfirmDelete = false;
                this.blnAltStatus = true;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.progress = 0;
                //delay 1 sec
                setTimeout(() => {
                  if (this.$refs.header != undefined) {
                    this.$refs.header.onClear();
                  }
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
              } else {
                // this.blnAltLoadingData = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                // setTimeout(() => {
                //   this.blnAltLoadingData = false;
                // }, 5000);
                this.blnOpenform = false;
                //ถ้าลบไปแล้ว ไม่สามารถลบซ้ำได้อีก
                if (
                  res.data.message ==
                  "Cannot delete ,Please check mapping Id in interface"
                ) {
                  //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                  this.blnnotdelete = true;
                  setTimeout(() => {
                    this.blnnotdelete = false;
                    this.processLoadingData();
                  }, 5000);
                } else {
                  //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                  this.blnAltLoadingData = true;
                  setTimeout(() => {
                    this.blnAltLoadingData = false;
                    this.processLoadingData();
                  }, 5000);
                }
              }
            } else {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              this.intWrongLogin = reslogin.WrongLogin;
              this.errorMsg = this.$t("357", {
                number: this.intPinCount - this.intWrongLogin,
              });
              this.blnError = true;
              this.$refs.AlertPopup.blnShowMSG = true;
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
            this.blnloadConfirmDelet = false;
          } else {
            this.blnloadConfirmDelet = false;
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
          }
        }
      } catch (error) {
        this.blnloadConfirmDelet = false;
        this.$refs.AlertPopup.blnShowMSG = true;
        console.log(error);
      }
    },
    // ดึงข้อมูลคำนำหน้าชื่อเพื่อมาอสดงในตาราง
    async fetchListPrefix() {
      this.blnloadError = true;
      await axios
        .get(urlapi + apiprefix.getPrefixList)
        .then((res) => {
          this.ListDummy = res.data;
          this.ListPrefix = res.data;
          this.blnloadError = false;
        })
        .catch((e) => {
          // this.blnAltLoadingData = true;
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            // this.blnAltLoadingData = false;
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListPrefix.data = ListDummy;
      for (var field in activeFilters) {
        this.ListPrefix.data = this.ListPrefix.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListPrefix.data.length,
          this.ListPrefix.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;
      this.ListDummy.data = ListDummy;
    },
    //รับค่าจาก component filter
    filteredData(activeFilters) {
      this.activeFiltersSelected = activeFilters;

      this.filter(activeFilters, this.ListDummy.data);
    },
    // เปิด Component filter ที่กดจาก header
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    //ค้นหาข้อมูลคำนำหน้าชื่อ 
    SearcEducation(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event);
    },
    // clear ข้อมูลค้นหา
    clean() {
      this.strSearch = "";
      this.SearcEducation("");
      //this.filteredData(this.activeFiltersSelected);
    },
    // ปิดฟอร์ม
    closeForm(event) {
      this.$emit("onInput", event);
    },
    //เปิดฟอร์ม
    async openform(state) {
      try {
        //ปิดฟอร์ม
        if (state == false) {
          this.listRelate = [];
          this.PrefixId = 0;
          this.formdataDummy = {};
          this.ListDummy = {};
          this.ListPrefix = {};
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.formInactive = [];
          this.blnloading = true;
          //กำหนด action insert
          this.action = "Insert";
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };

          this.formInactive.push(activeDelete);
          //เคลียข้อมูลในฟอร์ม
          this.formdata = {};
          setTimeout(() => {
            this.blnloading = false;
          }, 600);
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },
    //บันทึกการตั้งค่า configdisplay
    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem("SelectedListPrefix", JSON.stringify(SelectedList));
    },
    //เรียกบันทึกการตั้งค่า configdisplay
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListPrefix"))
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_custom.scss";
.form-lg-fix {
  min-width: 800px !important;
  max-width: 910px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
// .popup-container {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 9999;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .overlay {
//     background-color: rgba(0, 0, 0, 0.263);
//     width: 100%;
//     height: 100%;
//     position: absolute;
//   }
// }
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}
.form-fix {
  // max-width: max-content;
  width: 820px;
}
</style>
